<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <c-card class="cardClassDetailForm bcs-user-info-card" :isTitle="false">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 bcs-user-info">
            근로자 : (만 {{survey.age}}세 {{survey.sexName}}) {{survey.deptName}} / {{survey.userName}}<br/>
            조사일 : {{survey.surveyDate}}
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <c-table
        title="뇌심혈관계 평가 이력"
        :columns="gridEvalHistory.columns"
        :data="gridEvalHistory.data"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @linkClick="evalHistoryLinkClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
      <c-table
        title="상담 이력"
        :columns="gridConsultHistory.columns"
        :data="gridConsultHistory.data"
        :merge="gridConsultHistory.merge"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @linkClick="consultHistoryLinkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'brainCardiovascularPostProcessing',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
      }),
    },
    survey: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      }),
    },
    evalItems: {
      type: Array,
      default: () => [],
    },
    evalItemClasses: {
      type: Array,
      default: () => [],
    },
    riskPredictions: {
      type: Array,
      default: () => [],
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridEvalHistory: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '근로자',
            align: 'center',
            sortable: false,
            style: 'width:120px',
            type: 'link'
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'age',
            field: 'age',
            label: '나이(조사시점)',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'surveyDate',
            field: 'surveyDate',
            label: '조사일',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      gridConsultHistory: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'suspectFlagName',
            field: 'suspectFlagName',
            style: 'width: 100px',
            label: '상담자 구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '상담자',
            align: 'center',
            sortable: true,
          },
          {
            name: 'consultDate',
            field: 'consultDate',
            label: '상담일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'consultTypeName',
            field: 'consultTypeName',
            label: '상담구분',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'consultContent',
            field: 'consultContent',
            label: '상담내용',
            align: 'left',
            style: 'width: 220px',
            sortable: false,
            type: 'link',
          },
        ],
        data: [],
      },
      editable: true,
      surveyListUrl: '',
      consultListUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    sexAge() {
      return `${this.survey.age} / ${this.survey.sexName}`
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.surveyListUrl = selectConfig.hea.bcs.survey.list.url
      this.consultListUrl = selectConfig.hea.checkup.suspect.history.consult.url;
      // code setting
      // list setting
      this.getEvalHistoryList();
      this.getConsultHistoryList();
    },
    getEvalHistoryList() {
      this.$http.url = this.surveyListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.survey.userId
      };
      this.$http.request((_result) => {
        this.gridEvalHistory.data = _result.data;
      },);
    },
    getConsultHistoryList() {
      this.$http.url = this.consultListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.survey.userId,
        heaBrainCardiovascularSurveyId: this.popupParam.heaBrainCardiovascularSurveyId
      };
      this.$http.request((_result) => {
        this.gridConsultHistory.data = _result.data;
      },);
    },
    evalHistoryLinkClick(row) {
      this.popupOptions.title = '뇌심혈관계 상세';
      this.popupOptions.target = () => import(`${"./brainCardiovascularDetail.vue"}`);
      this.popupOptions.param = {
        heaBrainCardiovascularSurveyId: row.heaBrainCardiovascularSurveyId,
        isEvalHistory: true,
      };
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEvaltHistoryPopup;
    },
    closeEvaltHistoryPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getEvalHistoryList();
    },
    openPop(row) {
      this.popupOptions.title = '건강상담 상세';
      this.popupOptions.target = () => import(`${"@/pages/hea/consultDetail.vue"}`);
      this.popupOptions.param = {
        suspectConsultId: row? row.suspectConsultId : '',
        suspectId: row ? row.suspectId : '',
        suspectFlag: row ? row.suspectFlag : 'N',
        suspectUserName: row ? row.deptName + ' / ' +  row.userName : '',
        heaBrainCardiovascularSurveyId: row ? row.heaBrainCardiovascularSurveyId : this.popupParam.heaBrainCardiovascularSurveyId,
      };
      this.popupOptions.width = '80%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeConsultHistoryPopup;
    },
    consultHistoryLinkClick(row) {
      this.openPop(row);
    },
    closeConsultHistoryPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getConsultHistoryList();
    },
  }
};
</script>
<style lang="sass">
.bcs-user-info-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
.bcs-user-info
  font-size: 1.2rem
  text-align: center
  font-weight: 600
</style>